import * as React from 'react'

import { Text, TextProps } from './Text';

// does a few things
//  - preserves whitespace
//  - detects hyperlinks and makes them clickable
//
//  only supports text children

export function FormattedText(props:TextProps) {
  const {children, ...remaining} = props;

  const converted = convertLinks();

  function render() {
    return <Text preserveWhitespace {...remaining} css={linkCss}>{converted}</Text>
  }

  function convertLinks() {
    return React.useMemo(() => {
      if (typeof children != 'string') {
        return children;
      }

      return convertToClickableLinks(children)
    }, [children]);
  }

  return render();
}

FormattedText.fieldProps = {
  valueProperty:'children'
}

const linkCss = {
  '> a': {
    textDecoration: 'underline'
  }
}

function convertToClickableLinks(text:string):(string | JSX.Element)[] {
  const urlRegex = /(https?:\/\/[^\s]+)/;
  const emailRegex = /(\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b)/;
  const phoneRegex = /(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}/;

  const combinedRegex = new RegExp(`${urlRegex.source}|${emailRegex.source}|${phoneRegex.source}`,'g');

  return text.split(combinedRegex).map((part, i) => {
      if (!part) {
        return part;
      }

      if (urlRegex.test(part)) {
        return <a key={i} href={part}>{part}</a>;
      }
      
      if (emailRegex.test(part)) {
        return <a key={i} href={`mailto:${part}`}>{part}</a>;
      }

      if (phoneRegex.test(part)) {
        return <a key={i} href={`tel:${part}`}>{part}</a>;
      }

      return part;
    })
    .filter(Boolean);
}
